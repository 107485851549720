function loadSliders() {
	$('.center').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 3,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

new WOW({ offset: 70 }).init();

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});
	
	function fixHeights() {
		$('.testimonials__link').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	$('.clients__carousel').on('slide.bs.carousel', function (ev) {
		var dir = ev.direction === 'right' ? 'prev' : 'next';
		$('.clients__carousel').not('.sliding').addClass('sliding').carousel(dir);
	});

	$('.clients__carousel').on('slid.bs.carousel', function (ev) {
		$('.clients__carousel').removeClass('sliding');
	});
	
});

